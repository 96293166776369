import React from "react"
import Header from "./header"
import Footer from "./footer"
import { ParallaxProvider } from 'react-scroll-parallax';

const Layout = ({ children, siteSettings }) => (
  <ParallaxProvider>
    <div id="top"></div>
    <Header siteSettings={siteSettings} />

    {children}

    <Footer siteSettings={siteSettings} />
  </ParallaxProvider>
)

export default Layout
