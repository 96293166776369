import React from "react"
import ScrollToTop from "./scrollToTop"
// import SocialLinks from "./socialLinks"

const Footer = ({ siteSettings }) => {
  return (
    <footer className="py-4">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 d-flex align-items-center justify-content-between">
            <span className="copyright-text">{siteSettings.copyright}</span>
            {/* <SocialLinks /> */}
            <ScrollToTop />
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
