import React from "react"
import { Container } from "react-bootstrap"
import MainNav from "./mainNav"

const Header = ({ siteSettings }) => (
  <header className="bg-white">
    <Container fluid>
      <MainNav siteSettings={siteSettings} />
    </Container>
  </header>
)

export default Header
