import React, { useState } from 'react'
// import SocialLinks from "./socialLinks"
import { Link } from "gatsby"
import Navbar from 'react-bootstrap/Navbar'
import Image from 'gatsby-image'

const MainNav = ({ siteSettings }) => {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <Navbar variant={null} expand="lg" className={`mainnav ${collapsed ? 'collapsed' : ''}`} onToggle={(value) => setCollapsed(value)}>
      <Navbar.Toggle className="navbar-toggler-right" data-target="#navbarMobile" aria-controls="navbarMobile">
        <span className="icon-bar top-bar"></span>
        <span className="icon-bar middle-bar"></span>
        <span className="icon-bar bottom-bar"></span>
      </Navbar.Toggle>

      <Link to={`/${siteSettings.node_locale !== 'de' ? `${siteSettings.node_locale}/` : ''}`} className="navbar-brand logo">
        <Image fixed={siteSettings.logo.fixed} alt="Logo" />
      </Link>
      
      <div className="navbar-collapse d-none d-lg-flex justify-content-end">
        <ul className="nav navbar-nav">
          {siteSettings.navigation.map((item, index) => (
            <li key={index} className="nav-item">
              <Link to={`/${siteSettings.node_locale !== 'de' ? `${siteSettings.node_locale}/` : ''}${item.slug}`} className={`nav-link navHover ${index === (siteSettings.navigation.length - 1) ? 'prominent' : ''} ${index === 0 ? 'pl-0' : ''}`}>{item.name}</Link>
            </li>
          ))}
        </ul>
      </div>

      <Navbar.Collapse bsPrefix="collapse" id="navbarMobile">
        <ul className="nav navbar-nav">
          {siteSettings.navigation.map((item, index) => (
            <li key={index} className={`nav-item`}>
              <Link to={`/${siteSettings.node_locale !== 'de' ? `${siteSettings.node_locale}/` : ''}${item.slug}`} className={`nav-link navHover ${index === (siteSettings.navigation.length - 1) ? 'prominent' : ''}`}>{item.name}</Link>
            </li>
          ))}
        </ul>

        {/* <SocialLinks mobileNav={true} /> */}
      </Navbar.Collapse>
    </Navbar>
  )
}

export default MainNav
