import React from "react"
import Icon from "./icon"

const ScrollToTop = () => (
  <>
    <a className="scrollToTop" href="#top">
      <div className="d-flex justify-content-end align-items-center">
        <Icon name="scrollArrowUp" width="30" height="50" />
      </div>
    </a>
  </>
)

export default ScrollToTop
